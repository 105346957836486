/**
 * Layout component for tall header pages
 */

import React from 'react';

const BlockWithShadow = ({ children }) => (
  <div className="shadow-block">{children}</div>
);

export default BlockWithShadow;
